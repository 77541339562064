import React from "react"
import styled from "styled-components"
import VipRoom from "../components/VipRoom"
import VipRooms from "../components/VipRooms"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  margin-right: 128px;
  margin-left: 128px;
  margin-bottom: 128px;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const VipRoomsSection = () => (
  <Container>
    {VipRooms.map(room => {
      return <VipRoom {...room} />
    })}
  </Container>
)

export default VipRoomsSection
