import React from "react"
import styled from "styled-components"
import { TypographyLarge } from "../components/Typography"

const CustomSection = styled.div`
  position: relative;
  height: calc(80vh - 60px);
  background-color: #fff;
  @media (max-width: 800px) {
    height: fit-content;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-right: 128px;
  margin-left: 128px;
  @media (max-width: 800px) {
    text-align: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const MapContainer = styled.div`
  height: 60%;
  width: 45%;
  @media (max-width: 800px) {
    height: 300px;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 64px;
  }
`

const StyledTypographyLarge = styled(TypographyLarge)`
  max-width: 600px;
  @media (max-width: 800px) {
    font-size: 24px;
  }
`

const VipRoomsMapSection = () => (
  <CustomSection>
    <Container>
      <StyledTypographyLarge black>
        Junto com você. Onde você estiver!
        <br></br>
      </StyledTypographyLarge>
      <MapContainer>
        <iframe
          src="https://www.google.com/maps/d/u/0/embed?mid=1dUUyauKwuYJ_yS9REvrUNUCELM24YNk&ehbc=2E312F"
          width="100%"
          height="100%"
        ></iframe>
      </MapContainer>
    </Container>
  </CustomSection>
)

export default VipRoomsMapSection
