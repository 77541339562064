import React from "react"
import styled from "styled-components"
import {
  TypographySmallTitle,
  TypographyParagraph,
} from "../components/Typography"
import LocationPin from "../images/pin.png"
import { OutboundLink } from "gatsby-plugin-google-gtag"

interface PartnerProps {
  image: string
  partnerName: string
}

const Image = styled.img`
  height: 48px;
  width: 48px;
  object-fit: contain;
`

const StyledTypographySmallTitle = styled(TypographySmallTitle)`
  max-width: 600px;
  color: black;
  text-decoration: underline;
  text-decoration-color: black;
  @media (max-width: 800px) {
    font-size: 24px;
  }
`

const StyledTypographyParagraph = styled(TypographyParagraph)`
  color: black;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 33.33%;
  @media (max-width: 1400px) {
    width: 50%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`

const PinAndName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VipRoom: React.FC<PartnerProps> = ({ name, address, gmapsLink }) => (
  <Container>
    <OutboundLink href={gmapsLink}>
      <PinAndName>
        <Image src={LocationPin} />
        <StyledTypographySmallTitle>{name}</StyledTypographySmallTitle>
      </PinAndName>
    </OutboundLink>
    <StyledTypographyParagraph>{address}</StyledTypographyParagraph>
  </Container>
)

export default VipRoom
