import * as React from "react"
import { PageProps } from "gatsby"
import Seo from "../components/seo"
import GlobalFonts from "../fonts/fonts"
import Navbar from "../components/Navbar"
import VipRoomsMapSection from "../sections/VipRoomsMapSection"
import VipRoomsSection from "../sections/VipRoomsSection"
import Footer from "../components/Footer"

const GasStationsPage: React.FC<PageProps> = ({ location }) => (
  <>
    <Seo />
    <GlobalFonts />
    <Navbar location={location} />
    <VipRoomsMapSection />
    <VipRoomsSection />
    <Footer />
  </>
)

export default GasStationsPage
