const vipRooms = [
  {
    name: "Posto Trevão Matupa III - Sinop MT",
    address: "Rodovia BR 163, km 844, Sinop - MT, 78551-970",
    state: "Mato Grosso",
    gmapsLink: "https://goo.gl/maps/z8G2x3YgNAXj38jJ6",
  },
  {
    name: "Posto Araúna - Confresa MT",
    address: "Rod. Br 158 Km 158 - St. Industrial",
    state: "Mato Grosso",
    gmapsLink: "https://goo.gl/maps/ZfttfNJHmFYBaCsAA",
  },
  {
    name: "Posto Valen - São Luís MA",
    address: "Av. Eng. Emiliano Macieira, Km 02 São Luís - MA",
    state: "Maranhão",
    gmapsLink: "https://goo.gl/maps/B8fjreFsLyFAtnV66",
  },
  {
    name: "Posto Petrosoja - Balsas MA",
    address: "Av. Gov. Luiz Rocha, nº 30 - Potosi Balsas - MA",
    state: "Maranhão",
    gmapsLink: "https://goo.gl/maps/9jwwUNoMwXanv1Hn8",
  },
  {
    name: "Posto Dito - Colinas TO",
    address: "BR 153 Rua 04, Lote 01, Quadra 05, Sala 03 Santo Antônio",
    state: "Tocantins",
    gmapsLink: "https://goo.gl/maps/mfCTkuTpTvZv5UEB9",
  },
  {
    name: "Posto Du Figueiredo - Luzimangues TO",
    address: "Trevo TO 080 C/TO 348, Km 16 Porto Nacional",
    state: "Tocantins",
    gmapsLink: "https://goo.gl/maps/TRhAKYc2WapsuNX77",
  },
  {
    name: "Posto Cacique 33 - Uruçuí PI",
    address: "Av. Produtividade, nº 100, Bairro Portal Dos Cerrados",
    state: "Piauí",
    gmapsLink: "https://goo.gl/maps/oWuFHbwPz53qtiSr5",
  },
]

export default vipRooms
